export default [
    {
        words: ["ENFW"],
        type: "ux",
        profName: "UX designer"
    },
    {
        words: ["ENFP", "ENTW", "ENTP", "INFW"],
        type: "front",
        profName: "Programista front-end"
    },
    {
        words: ["ESFW", "ESFP", "ESTW", "INFP", "INTW", "ISFW"],
        type: "tester",
        profName: "Tester"
    },
    {
        words: ["ESTP", "INTP", "ISFP", "ISTW", "ISTP"],
        type: "back",
        profName: "Programista back-end"
    },
]