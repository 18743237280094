import decodedWords from "./testAnswersMap";

const INITIAL_STATE = {
    testVisible: false,
    questions: [],
    testTitle: "",
    testDetails: "",
    numberOfQuestions: 0,
    finishScreen: false,
    testAnswersMap: [],
    carierPathVisible: false,
    pathType: "front",
    userProf: {
        id: "",
        name: ""
    }
}



export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case "START_TEST": {
            return {
                ...state,
                testVisible: true,
                carierPathVisible: false,
            }
        }
        case "CLOSE_TEST": {
            return {
                ...state,
                testVisible: false,
            }
        }

        case "SHOW_PATH": {
            return {
                ...state,
                carierPathVisible: true,
            }
        }
        case "FINISH_TEST": {
            // const obj = {id: 1}//generateUserProffesion(state.testAnswersMap)
            return {
                ...state,
                // testVisible: false,
                finishScreen: true,
                userProf: action.obj,
                carierPathVisible: true,
                pathType: action.obj.id,
            }
        }
        case "SET_TEST": {
            return {
                ...state,
                questions: action.questions,
                testTitle: action.testTitle,
                testDetails: action.testDetails,
                numberOfQuestions: action.questions.length,
            }
        }

        case "SET_ACTIVE_SVG": {
            return {
                ...state,
                pathType: action.activeSvg,
            }
        }

        case "ADD_ANSWER": {
            const testAMTemp = [...state.testAnswersMap]
            const index = testAMTemp.findIndex(el => el.questionId === action.answerObj.questionId);

            let area = {};
            const n = action.answerObj.questionId;

            if (n === 1 || n === 5 || n === 9) {
                area = {area: "expression"};
            } else if (n === 2 || n === 6 || n === 10) {
                area = {area: "mind"};
            } else if (n === 3 || n === 7 || n === 11) {
                area = {area: "decisions"};
            } else if (n === 4 || n === 8 || n === 12) {
                area = {area: "perception"};
            }

            const answer = {...action.answerObj, ...area}
            if (index === -1) {
                testAMTemp.push(answer);
            } else {
                testAMTemp[index] = answer;
            }
            return {
                ...state,
                testAnswersMap: testAMTemp,
            }
        }

        default:
            return state;
    }
}