const INITIAL_STATE = {
  userEmail: "",
  userPhone: "",
  userCity: "",
  userExperience: false,
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "SAVE_USER": {
      return {
        ...state,
        userEmail: action.email,
        userPhone: action.phone,
        userCity: action.city,
        userExperience: action.experience,
      }
    }
    default:
      return state
  }
}
