import { createStore as reduxCreateStore, combineReducers } from "redux"

//Redux reducers
import testReducer from '../reducers/testReducer';
import userReducer from '../reducers/userReducer';

const INITIAL_STATE = {};
const rootReducer = combineReducers({
    testReducer,
    userReducer,
})

const createStore = () => reduxCreateStore(rootReducer, INITIAL_STATE)
export default createStore
